import React, { useEffect } from 'react';
import './LoadingAnimation.css';

const LoadingAnimation = () => {
  useEffect(() => {
    // Función para generar un valor aleatorio entre un rango
    function getRandomValue(min, max) {
      return Math.random() * (max - min) + min;
    }

    // Obtiene todos los elementos "wave"
    const waves = document.querySelectorAll('.wave');

    // Asigna valores aleatorios a cada "wave"
    waves.forEach(wave => {
      wave.style.animationDelay = getRandomValue(0, 1) + 's'; // Aplica un retraso de animación aleatorio
    });
  }, []); // Se ejecuta una vez al montar el componente

  return (
    <div class="wave-container">
      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>
    </div>
  );
};

export default LoadingAnimation;
