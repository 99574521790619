import React, { useState, useEffect } from "react";
import { Clipboard } from 'react-native';
import { StyleSheet, Text } from 'react-native'; // Asegúrate de importar Text de 'react-native'
import { Button, Input } from 'react-native-elements';
import { View } from 'react-native';
import { primaryColor, secondaryColor, activeColor, noColor, noColorMenu } from '../../utils/colors';
import Icon from 'react-native-vector-icons/FontAwesome';

const MedicalForm = ({
  name,
  placeholder,
  value, 
  isGenerated,
  isLogged,
  isDebugMode,
  onChangeText = () => {}
}) => {
  const [numberOfLines, setNumberOfLines] = useState(2); // Initial number of lines
  const [textHeight, setTextHeight] = useState(0); // Store the input height

  const styles = StyleSheet.create({
    inputContainer: {
      flexDirection: 'row',
      alignItems: 'flex-start' // Alinear elementos verticalmente en la parte superior
    },
    label: {
      marginRight: 10,
      paddingLeft: 10,
      marginTop: 5, // Agregar margen superior al texto para alinearlo con la parte superior del input
      color: secondaryColor,
      fontWeight: 'bold'
    },
    input: {
      flex: 1,
      borderColor: noColor,
      borderWidth: 1,
      borderRadius: 10,
      padding: 10,
      backgroundColor: 'white'
    },
    fieldheader:{
      display:'flex',
      flexDirection: 'row',
      marginBottom: '3px'
    },
    rowInputContainer:{
      width: '100%'
    },
    copyButton: {
      backgroundColor: noColor,
      color: primaryColor,
      borderRadius: 5,
      marginLeft: 5
    },
  });

  const copyText = (text) => {
    Clipboard.setString(text); 
  }

  return (
    <View style={styles.inputContainer}>
      <View style={styles.rowInputContainer}>
        <View style={styles.fieldheader}>
          <Text style={styles.label}>{name}</Text>
          <Button
            title=""
            icon={<Icon name={"copy"} size={10} color={secondaryColor} />}
            onPress={() => copyText(value)}
            containerStyle={{ alignSelf: 'center', marginTop: '5px' }}
            disabled={!isGenerated}
            buttonStyle={styles.copyButton}
          />
        </View>
        <Input
          placeholder={placeholder}
          value={value}
          onChangeText={(value) => onChangeText(value)}
          inputContainerStyle={styles.input}
          inputStyle={{ color: secondaryColor }} // Establecer color del texto del input
          disabled={!isGenerated}
          multiline={true}
          editable={isLogged}
          numberOfLines={(value.length/75)+4} // Dynamically set number of lines
          selectTextOnFocus={false}
        />
      </View>
    </View>
  );
};

export default MedicalForm;
