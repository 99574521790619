// Import the functions you need from the SDKs you need
const firebase = require("firebase");
// Required for side-effects
require("firebase/firestore");
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDPuw4iM5vWc1o3neydsPl9qeTSFoVjCro",
  authDomain: "health-sage.firebaseapp.com",
  projectId: "health-sage",
  storageBucket: "health-sage.appspot.com",
  messagingSenderId: "540165129523",
  appId: "1:540165129523:web:269391ba1a3873a3136a46",
  measurementId: "G-E38DZN5L2F"
};

// Initialize Firebase
const FireBase = firebase.initializeApp(firebaseConfig);
export default FireBase;