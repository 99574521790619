import { BrowserRouter, Route, Routes} from 'react-router-dom';
import MainScreen from './pages/MainScreen';

const Router = () => (
    <BrowserRouter>
        <Routes>   
            <Route path="/cc/:countryCode" index element={<MainScreen/>} />
            <Route path="/qr/:qrCode" index element={<MainScreen/>} />
            <Route path="*" element={<MainScreen/>} />
        </Routes>
    </BrowserRouter>
);

export default Router;