import React from "react";
import { Clipboard } from 'react-native';
import { StyleSheet } from 'react-native';
import { Button, Input } from 'react-native-elements';
import { View } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { primaryColor, secondaryColor, activeColor, noColor, noColorMenu } from '../../utils/colors';
import logoPNG from '../../../assets/favicon.png'

const MainMenu = ({
  isLoading,
  isDebugMode,
  isLogged,
  isRecording,
  isGenerated,
  isReadyToProcess,
  isWaitingRecord,
  connected,
  startDebug = () => {},
  startRecording = () => {},
  stopRecording = () => {},
  copyText = () => {},
  deleteText = () => {},
  processRecord = () => {},
  LoadingAnimation,
  TraceOperationRPA = () => {},
}) => {
  const styles = StyleSheet.create({
    startButton: {
      backgroundColor: activeColor,
      borderRadius: 10,
      marginRight: 15
    },
    buttonContainer: {
      flex: 1,
      width: '85%',
      maxWidth: '85%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: 10,
      paddingRight: 0,
      marginBottom: 10,
      backgroundColor: noColor,
      padding: '10px',
      borderRadius: '15px'
    },
    button: {
      flex: 1,
    },
    processButton: {
      backgroundColor: activeColor,
      borderRadius: 10,
      marginRight: 15
    },
    copyButton: {
      backgroundColor: activeColor,
      borderRadius: 10,
      marginRight: 15
    },
    trashButton: {
      backgroundColor: activeColor,
      borderRadius: 10,
      marginRight: 15
    },
    stopButton: {
      backgroundColor: activeColor,
      borderRadius: 10,
      marginRight: 15
    },
  })

  return (
    <View style={styles.buttonContainer}>
      <Button
        icon={isRecording && !isLoading ? <Icon name="dot-circle-o" size={24} color="red" /> : <Icon name="microphone" size={24} color={secondaryColor} />}
        title=""
        onPress={startRecording}
        disabled={isRecording | isGenerated | (!isLogged)}
        containerStyle={styles.button}
        buttonStyle={styles.startButton}
      />
      <Button
        icon={isWaitingRecord ? <LoadingAnimation /> : <Icon name="pause" size={24} color={secondaryColor} />}
        title=""
        onPress={stopRecording}
        disabled={!isRecording | isLoading}
        containerStyle={styles.button}
        buttonStyle={styles.stopButton}
      />
      <Button
        icon={isLoading ? <LoadingAnimation /> : <img src={logoPNG} alt="Logo" style={{ width: '24px', height: '24px' }} />}
        title=""
        onPress={processRecord}
        disabled={!isReadyToProcess| isRecording | isLoading | !isLogged}
        containerStyle={styles.button}
        buttonStyle={styles.stopButton}
      />
      <Button
        title=""
        icon={<Icon name={"send"} size={24} color={secondaryColor} />}
        onPress={TraceOperationRPA}
        containerStyle={styles.button}
        disabled={!isGenerated | !connected}
        buttonStyle={styles.copyButton}
      />
      <Button
        title=""
        icon={<Icon name={"copy"} size={24} color={secondaryColor} />}
        onPress={copyText}
        containerStyle={styles.button}
        disabled={!isGenerated}
        buttonStyle={styles.copyButton}
      />
      <Button
        title=""
        icon={<Icon name="trash" size={24} color={secondaryColor} />}
        onPress={deleteText}
        containerStyle={styles.button}
        buttonStyle={styles.trashButton}
        disabled={isRecording | (!isReadyToProcess & !isGenerated)}
      />
    </View>
  );
};

export default MainMenu;
