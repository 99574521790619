import React, { useEffect, useRef, useState } from "react";
import { flags } from "./assets/flags/";

const LanguageSelector = ({
  firebase,
  current = "ES",
  available = [],
  direction = "down",
  colDirection = ["up", "down"].indexOf(direction) !== -1 ? "right" : "down",
  rows,
  onChange = () => {},
  onChangeEnd = () => {},
  setTranslation = () => {}
}) => {
  
  const pickerRef = useRef();
  const [language, setLanguage] = useState(current);
  const [selectorActive, setSelectorActive] = useState(false);
  const [translations, setTranslations] = useState([]); // Estado para almacenar las traducciones

  const toggleLanguageSelector = () => {
    setSelectorActive(!selectorActive)
  };

  const handleChangeLanguage = async (lang) => {
    setSelectorActive(false);
    setLanguage(lang);
    setTimeout(() => {
      onChangeEnd(lang, flags[lang]);
    }, 300);
  };

  const imageStyles = { 
    borderRadius: '5px', 
    shadowColor: '#000',
    shadowOffset: { 
      width: 3, 
      height: 0 
    },
    shadowOpacity: 0.3,
    shadowRadius: 0,
    marginRight: '5px'
  };

  const generateAvailableLanguages = () =>
    available
      .filter((lang) => lang !== language)
      .map((lang, i) => (
        <div key={i} onClick={()=>handleChangeLanguage(lang)}>
          <img style={imageStyles} src={flags[lang].img} />
        </div>
      ));
  
  useEffect(() => {
    // Función que se llama al inicio para recuperar las traducciones
    const fetchTranslations = async () => {
      try {
        const snapshot = await firebase.collection('SELENE').get();
        const translationObj = {};
        snapshot.forEach(doc => {
          const docName = doc.id;
          const translationData = doc.data();
          translationObj[docName] = translationData;
        });
        console.log('TRANSLATIONS:', translationObj);
        setTranslations(translationObj);
        setTranslation(translationObj)
      } catch (error) {
        console.error('Error fetching translations:', error);
      }
    };

    fetchTranslations(); // Llama a la función para recuperar las traducciones al inicio
  }, []); // El segundo argumento [] asegura que este efecto se ejecute solo una vez, al inicio de la montura del componente
  
  return (
      <div style={{ display: 'flex'}}>
        <div onClick={()=>toggleLanguageSelector(language)}>
          <img style={imageStyles} src={flags[language].img} />
        </div>
        {selectorActive && (
          generateAvailableLanguages()
        )}
      </div>
  );
};

export default LanguageSelector;
