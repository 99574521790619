import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Modal } from 'react-native';
import { Input } from 'react-native-elements';
import Icon from 'react-native-vector-icons/FontAwesome';
import { activeColor, noColor, primaryColor, secondaryColor } from '../../utils/colors';

const TranscriptionField = ({ transcription, isLogged, isDebugMode, trasOpen, openCloseTras, setTrans }) => {
  const [activeTab, setActiveTab] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const countWords = () => {
    if (!transcription) return 0;
    const words = transcription.trim().split(/\s+/);
    return words.length;
  }
  
  const handleTabClick = (tab) => {
    if (activeTab === tab) {
      setActiveTab(null);
      openCloseTras();
    } else {
      setActiveTab(tab);
      openCloseTras();
    }
  };

  const handleInputChange = (value) => {
    setInputValue(value);
    setTrans(value);
  };

  return (
    <View style={styles.container}>
      <View style={styles.tabsClose}>
        <ScrollView contentContainerStyle={styles.scrollContainer} showsVerticalScrollIndicator={false}>
          <TouchableOpacity onPress={() => handleTabClick(1)} style={styles.tab}>
            <Icon name="file-text" size={24} color={secondaryColor} />
            <Text style={styles.counter}>{countWords()}</Text>
          </TouchableOpacity>
          {/* <TouchableOpacity onPress={() => handleTabClick(1)} style={styles.tab}>
            <Icon name="history" size={24} color={secondaryColor} />
            <Text style={styles.counter}>{countWords()}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handleTabClick(1)} style={styles.tab}>
            <Icon name="flask" size={24} color={secondaryColor} />
            <Text style={styles.counter}>{countWords()}</Text>
          </TouchableOpacity> */}
        </ScrollView>
      </View>

      {trasOpen && (
        <Modal
          animationType="slide"
          transparent={true}
          visible={activeTab !== null}
          onRequestClose={() => {
            setActiveTab(null)
            openCloseTras()
          }}
        >
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
                <Input
                 placeholder=""
                 value={transcription}
                 onChangeText={(value) => setTrans(value)}
                 inputContainerStyle={styles.inputContainer}
                 inputStyle={styles.input}
                 multiline={true}
                 disabled={!isLogged || !isDebugMode}
                 editable={isLogged && isDebugMode}
                 numberOfLines={15}
                />
                <TouchableOpacity onPress={() => {
                  setActiveTab(null)
                  openCloseTras()
                  }} style={styles.closeButton}>
                  <Icon name="times" size={24} color={secondaryColor} />
                </TouchableOpacity>
            </View>
          </View>
        </Modal>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 120
  },
  tabsClose: {
    position: 'absolute',
    height: '80%',
    width: 65,
    paddingRight: 0,
    right: 0,
    zIndex: 2,
    backgroundColor: primaryColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    
  },
  scrollContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 20,
  },
  tab: {
    width: 60,
    height: 60,
    backgroundColor: activeColor,
    marginBottom: 20,
    marginLeft:5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    position: 'relative',
  },
  counter: {
    position: 'absolute',
    top: 5,
    right: 0,
    color: 'white',
    fontSize: 12,
    backgroundColor: 'red',
    borderRadius: 12,
    padding: 2,
    minWidth: 24,
    textAlign: 'center',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  inputContainer: {
    borderBottomWidth: 0, // Elimina el borde inferior por defecto
  },
  modalContent: {
    width: '80%',
    maxHeight: '80%',
    backgroundColor: primaryColor,
    padding: 20,
    paddingTop:50,
    paddingBottom:40,
    borderRadius: 10,
  },
  input: {
    width: '100%',
    marginBottom: 20,
    borderColor: activeColor,
    backgroundColor:'white',
    borderWidth:1,
    borderRadius:20, 
    padding: 10
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
});

export default TranscriptionField;
