// src/components/GoogleLogin/GoogleLogin.js
import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Button } from 'react-native-elements';
import { StyleSheet, View } from 'react-native';
import { primaryColor, secondaryColor, activeColor, noColor, noColorMenu } from '../../utils/colors';

import Cookies from 'js-cookie';
import {
  useGoogleLogin
} from "@react-oauth/google";
function GoogleLogin(props) {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'left',
      alignItems: 'left',
      padding: 10,
      backgroundColor: primaryColor,
    },
    buttonContainer: {
      flex: 1,
      width: '100px',
      marginBottom: 10
    },
    button: {
      flex: 1,
    },
    startButton: {
      backgroundColor: activeColor,
      borderRadius: 10,
      marginRight: 5,
      shadowColor: '#000', // Color de la sombra
      shadowOffset: { width: 3, height: 0 }, // Desplazamiento de la sombra (eje x, eje y)
      shadowOpacity: 0.3, // Opacidad de la sombra
      shadowRadius: 0,
      fontSize: 16,
      color: primaryColor,
    }
  });

  const login = useGoogleLogin({
    scope: [
      "https://www.googleapis.com/auth/cloud-platform"
    ],
    lifetime: 3600,
    cookiePolicy: "none",
    onSuccess: async codeResponse => {
      console.log("Token:", codeResponse)
      props.onTokenReceived(codeResponse.access_token)
      const expiracion = new Date(new Date().getTime() + 60 * 60 * 1000);
      Cookies.set('hs-token', codeResponse.access_token, { expires: expiracion });

      const headers = {
        Authorization: `Bearer ${codeResponse.access_token}`
      };
      const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        console.error(response.statusText);
        throw new Error("Request failed " + response.statusText);
      }
      const result = await response.json();
      props.onEmailReceived(result.email, result.picture)
      console.log("IDENTITY_TOKEN:", result);
      props.firestore.collection("roles")
        .doc(String(result.email))
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.exists) {
            const data = querySnapshot.data();
            console.log("NEW APP:",data.apps)
            console.log("NEW APP SELECTED:",data.apps.find(appObj => appObj.default)?.app)
            props.setMedicalApps(data.apps)
            props.setMedicalApp(data.apps.find(appObj => appObj.default)?.app)
          } else {
            console.log("ROL:", [{ app: "DEFAULT", default: true }]);
            props.setMedicalApps([{ app: "DEFAULT", default: true }])
            props.setMedicalApp("DEFAULT")
          }
        })
        .catch((error) => {
          console.error("Error fetching document:", error);
        });
    },
    onError: error => {
      console.error('OAuth Error', error);
    }
  })

  return (
    <View style={styles.container}>
      <View style={styles.buttonContainer}>
        <Button
          icon={<Icon name="google" size={24} color={secondaryColor} />}
          color={secondaryColor}
          title={" Login"}
          onPress={() => login()}
          buttonStyle={styles.startButton}
        />
      </View>
    </View>
  );
}

export default GoogleLogin;