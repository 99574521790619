import React, { useState, useCallback, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Button, Divider, Input, Switch } from 'react-native-elements';
import { diariceText, getTextPalm, offuscateText } from '../src/services/llm'
import Icon from 'react-native-vector-icons/FontAwesome';
import { Clipboard, Image } from 'react-native';
import annyang from '../src/utils/annyang';
// import AudioRecognizer from '../src/utils/AudioRecognizer';
import GoogleLogin from '../src/components/GoogleLogin/GoogleLogin';
import { GoogleOAuthProvider } from "@react-oauth/google";
import LoadingAnimation from '../src/components/LoadingAnimation/LoadingAnimation'
import LanguageSelector from '../src/components/LanguageSelector'
import MainMenu from '../src/components/MainMenu'
import MedicalForm from '../src/components/MedicalForm'
import TranscriptionField from '../src/components/TranscriptionField'
import FireBase from "../src/Components/Firebase";
import { flags } from "../src/components/LanguageSelector/assets/flags";
import { primaryColor, secondaryColor, activeColor, noColor, noColorMenu } from '../src/utils/colors';
const { v4: uuidv4 } = require('uuid');
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import Modal from 'react-modal';

const MainScreen = () => {
  const [text, setText] = useState('');
  const [trans, setTrans] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [resultText, setResultText] = useState('');
  const [token, setToken] = useState('');
  const [additionalText, setAdditionalText] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [isDebugMode, setIsDebugMode] = useState(false); // State for loading indicator
  const [debugPosible, setDebugPosible] = useState(false)
  const [voiceInput, setVoiceInput] = useState('');
  const [lastVoiceInput, setLastVoiceInput] = useState('');
  const [sliderValue, setSliderValue] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [normalMode, setNormalMode] = useState(true);
  const [connected, setConnected] = useState(false);
  const saveToFirebase = FireBase.firestore();
  const [operationId, setOperationId] = useState(uuidv4())
  const [inputBilled, setInputBilled] = useState(0);
  const [outputBilled, setOutputBilled] = useState(0);
  const [result, setResult] = useState("");
  const [user, setUser] = useState("");
  const [picture, setPicture] = useState("");
  const [medicalApp, setMedicalApp] = useState("");
  const [operationDatetime, setOperationDatetime] = useState(getCurrentUtcDateTime())
  const [pressTimer, setPressTimer] = useState()
  const { countryCode } = useParams();
  const { qrCode } = useParams();
  const [modelResults, setModelResults] = useState()
  const [socket, setSocket] = useState(null)
  const [isMobile, setIsMobile] = useState(false);
  const [translations, setTranslation] = useState(undefined)
  const [languagePrompt, setLanguagePrompt] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [isReadyToProcess, setIsReadyToProcess] = useState(false)
  const [isWaitingRecord, setIsWaitingRecord] = useState(false)
  const [medicalApps, setMedicalApps] = useState(["SMS"])
  const [trasOpen, setTrasOpen] = useState(false)
  const [modelOutput, setModelOutput] = useState({})
  const [translation, setTranslations] = useState({})
  const [isAnnyangListening, setIsAnnyangListening] = useState(false)
  const [isWaitingToPause, setIsWaitingToPause] = useState(false)
  const [pauseAnnyang, setPauseAnnyang] = useState(true)
  const [languages, setLanguages] = useState(["ES"])

  const handleSelectMedicalApp = (event) => {
    setMedicalApp(event.target.value);
    setIsGenerated(false);
  };

  console.log("DEBUG:", flags[countryCode])
  var selectedConfig = "ES"
  if (countryCode && (flags[countryCode.toUpperCase()] != undefined)) {
    selectedConfig = countryCode.toUpperCase();
  }
  const [language, setLanguage] = useState(selectedConfig);
  const [languageConfig, setLanguageConfig] = useState(flags[language]);
  const [dto, setDto] = useState()
  const toggleSwitch = () => setNormalMode(previousState => !previousState);
  const toggleSwitchDebug = () => setIsDebugMode(previousState => !previousState);
  const appVersion = '4.0.4-'; // Reemplaza esto con la versión de tu aplicación
  const modelVersion = 'GMN_1.5p' //'P2_TB_0.0.1'

  const versionStyle = {
    position: 'fixed',
    bottom: 5,
    right: 25,
    color: noColor, // Puedes personalizar el color según tu preferencia
    fontSize: '10px',
    zIndex: 2
  };

  const enableModal = () => {
    setShowModal(true);
    // Ocultar el modal después de 3 segundos
    setTimeout(() => {
      setShowModal(false);
    }, 3000);
  };

  function getCurrentUtcDateTime() {
    const now = new Date();

    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const seconds = String(now.getUTCSeconds()).padStart(2, '0');

    const formattedUtcDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedUtcDateTime;
  }

  useEffect(() => {
    const tokenFromCookie = Cookies.get('hs-token');
    if(qrCode){
      console.log("QR CODE:", qrCode)
      setConnected(true);
    }
    console.log("COOKIE:", tokenFromCookie)
    console.log(token)
    if ((token == "") && tokenFromCookie) {
      const headers = {
        Authorization: `Bearer ${tokenFromCookie}`
      };
      fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        method: "GET",
        headers,
      })
        .then(response => {
          if (!response.ok) {
            console.error(response.statusText);
            throw new Error("Request failed " + response.statusText);
          }
          return response.json();
        })
        .then(result => {
          console.log(String(result.email))
          saveToFirebase.collection("roles")
            .doc(String(result.email))
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.exists) {
                const data = querySnapshot.data();
                setMedicalApps(data.apps)
                setMedicalApp(data.apps.find(appObj => appObj.default == true)?.app)
                console.log("ROL:", data.apps);
              } else {
                console.log("ROL:", [{ app: "DEFAULT", default: true }]);
                setMedicalApps([{ app: "DEFAULT", default: true }])
                setMedicalApp("DEFAULT")
              }
            })
            .catch((error) => {
              console.error("Error fetching document:", error);
            });
          setUser(result.email)
          setPicture(result.picture)
        })
        .catch(error => {
          console.error('Error en la solicitud:', error);
        });

      setToken(tokenFromCookie);
      setIsLogged(true)
    }
  })

  useEffect(() => {
    console.log("Tranlations:", translations)
    if (translations) {
      console.log("Languages:", translations['config']['lenguages'])
      setLanguages(translations['config']['languages'])
      console.log("LANGUAJE:", language)
      console.log("TRANS:", translations)
      setTranslations(translations)
      setLanguagePrompt(translations['prompts'][language])
      console.log("LANGCONFIG:", languagePrompt)
      asignLanguageConfig(languageConfig, language)

    }
    return () => {
    };
  }, [translations, languagePrompt]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this value according to your breakpoint
    };

    handleResize(); // Check on component mount

    window.addEventListener('resize', handleResize); // Add event listener for resize

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up on component unmount
    };
  }, []);

  const asignLanguageConfig = (langConfig, lang) => {
    const transformedFields = {};
    Object.keys(translations['fields']).forEach((fieldKey) => {
      const fieldData = translations['fields'][fieldKey][lang];
      if (fieldData) {
        transformedFields[fieldKey] = {
          name: fieldData.fieldname,
          order: translations['fields'][fieldKey]['order'],
          placeholderFieldMsg: fieldData.placeholder,
        };
      }
    })
    langConfig.fields = transformedFields
    setLanguageConfig(langConfig);
  }

  const handleLanguageOnChangeEnd = (lang, langConfig) => {
    console.log("Languaje selected:", langConfig)

    console.log("Fields:", languageConfig)
    setLanguage(lang);
    setLanguagePrompt(translations['prompts'][lang])
    asignLanguageConfig(langConfig, lang);
    console.log(languagePrompt)
  };

  const trace_operation = (data: any) => {
    console.log("Trace:", operationId, data)
    const docRef = saveToFirebase.collection("operations").doc(operationId);
    docRef.set(data);
  };

  const trace_operation_rpa = () => {
    var data = {}
    for (const key in languageConfig.fields) {
      // console.log("DEBUG FIELDS[",key,"]:", languageConfig.fields[key])
      data[key] = modelOutput[key]
    }
    data["app"] = medicalApp
    data["datetime"] = new Date()
    const docRef = saveToFirebase.collection("rpas").doc(qrCode);
    docRef.set(data);
  }

  const handleChange = (event: any) => {
    if (event.target.value != sliderValue) {
      setSliderValue(event.target.value);
    }
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleSpeechResult = useCallback((result: any) => {
    if (result != lastVoiceInput) {
      console.log("Se añade:", result[0])
      setLastVoiceInput(result[0])
    }
  }, []);


  useEffect(() => {
    if (text.includes("DEBUG")) {
      setIsDebugMode(true)
    }
    return () => { };
  }, [text]);


  useEffect(() => {
    if (medicalApp) {
      console.log("Medical App:", medicalApp)
      saveToFirebase.collection(String(medicalApp))
        .get()
        .then((querySnapshot) => {
          const translations = [];
          querySnapshot.forEach((doc) => {
            // Para cada documento en la colección, obtenemos los datos y los agregamos a un array
            console.log(doc)
            translations[doc.id] = doc.data();
          });
          // Ahora `translations` contendrá todos los documentos de la colección
          console.log("Documentos de la colección:", translations);
          setTranslation(translations)
        })
        .catch((error) => {
          console.error("Error al obtener documentos de la colección:", error);
        });
    }
    return () => { };
  }, [medicalApp]);

  useEffect(() => {
    var sep = ''
    if (voiceInput != '') {
      '. '
    }
    if (lastVoiceInput != '') {
      setVoiceInput(voiceInput + sep + lastVoiceInput)
      setTrans(voiceInput + sep + lastVoiceInput)
    }
    return () => { };
  }, [lastVoiceInput]);

  useEffect(() => {
    if (text.includes("DEBUG")) {
      setIsDebugMode(true)
    }
    return () => { };
  }, [text]);

  useEffect(() => {
    setIsReadyToProcess(voiceInput != '')
    return () => { };
  }, [voiceInput]);

  useEffect(() => {
    setIsReadyToProcess(trans != '')
    return () => { };
  }, [trans]);


  useEffect(() => {
    if(isWaitingToPause && !isAnnyangListening){
      stopAnnyangCustom()
      setIsWaitingToPause(false)
    }
    return () => { };
  }, [isAnnyangListening, isWaitingToPause]);


  const startRecording = () => {
    setIsRecording(true);
    console.log("Annyang configurated with:", languageConfig.annyangConfig)
    // AudioRecognizer.start(handleSpeechResult, token)
    annyang.setLanguage(languageConfig.annyangConfig);
    annyang.start({ autoRestart: true, continuous: false });
    annyang.addCallback('result', handleSpeechResult);
    annyang.addCallback('soundstart', () => {
      setIsAnnyangListening(true)
    });
    annyang.addCallback('soundend', async () => {
      setIsAnnyangListening(false)
    })
  }

  const closeSession = () => {
    setIsLogged(false)
    Cookies.remove('hs-token');
    setIsRecording(false)
    setIsLoading(false)
    setIsGenerated(false)
    enableModal()
  }
  const generateDoc = async (chat) => {
    setIsLoading(true);
    //AudioRecognizer.pause()

    console.log("Chat", chat)
    var results = await getTextPalm(chat, token, languageConfig, languagePrompt, "general")
    if (results.dto) {
      var updateModelOutput = modelOutput
      console.log("Fields:", languageConfig.fields)
      for (const key in languageConfig.fields) {
        // console.log("DEBUG FIELDS[",key,"]:", languageConfig.fields[key])
        updateModelOutput[key] = results.dto[key]
      }
      setModelOutput(updateModelOutput)
      console.log(modelOutput)
      setModelResults(results.dto)

      console.log("TEST OFFUS CHAT:", chat)
      var offuscatedTranscription = await offuscateText(chat, token)
      if (offuscatedTranscription.dto) {
        var dto = {
          datetime: operationDatetime,
          transcription: offuscatedTranscription.dto,
          model_version: modelVersion,
          model_results: results.dto,
          adjustment: additionalText,
          operation_id: operationId,
          language: languageConfig.country,
          input_characters: results.inputCost,
          output_characters: results.outputCost,
          user: user,
        };
        setInputBilled(results.inputCost)
        setOutputBilled(results.outputCost)
        trace_operation(dto);
        setVoiceInput('');
        setIsLoading(false);
        setIsRecording(false);
        setIsGenerated(true);
      } else {
        closeSession()
      }
    } else {
      closeSession()
    }

  }

  const stopAnnyangCustom = async () => {
    annyang.pause()
    if((voiceInput != "") || (trans != "")){
      const diarizedText = await diariceText(voiceInput ? voiceInput : trans, token)
      console.log("Diariezed result: ", diarizedText)
      if(diarizedText.dto){
        if (voiceInput != "") {
          setVoiceInput(diarizedText.dto)
        }
        if(trans != ""){
          setTrans(diarizedText.dto)
        }
      } else {
        closeSession()
      } 
    }
    setIsWaitingRecord(false)
    setIsRecording(false);
  }

  const stopRecording = async () => {
    setIsWaitingRecord(true)
    if(isAnnyangListening){
      setIsWaitingToPause(true)
    } else {
      await stopAnnyangCustom()
    }
  };

  const processRecord = async () => {
    await generateDoc(voiceInput ? voiceInput : trans)
  }

  const copyText = async () => {
    var clipboardText = ""
    for (const key in languageConfig.fields) {
      if(modelOutput[key] != "No especificado") {
        clipboardText += "# " + languageConfig.fields[key].name + ":\n" + modelOutput[key] + "\n\n";
      }
    }
    Clipboard.setString(clipboardText); // Copy the content of the additional text box

    var trs = trans
    if (trs == "") {
      trs = voiceInput
    }
    var adjustment = {}

    for (const key in languageConfig.fields) {
      adjustment[key] = modelOutput[key]
    }

    console.log("TEST OFFUS TRS:", trs)
    var offuscatedTranscription = await offuscateText(trs, token)
    if (offuscatedTranscription.dto) {
    var dto = {
      type: medicalApp,
      datetime: operationDatetime,
      transcription: offuscatedTranscription.dto,
      model_version: modelVersion,
      model_results: modelResults,
      adjustment: adjustment,
      operation_id: operationId,
      language: languageConfig.country,
      input_characters: inputBilled,
      output_characters: outputBilled,
      user: user,
    };
    trace_operation(dto);
    if (connected && socket) {
      socket.send(JSON.stringify(dto));
    } else {
      console.log('No se puede enviar el mensaje porque no hay conexión');
    }
  } else {
    closeSession()
  }
  };

  const startDebug = async () => {
    if (trans != "") {
      console.log("Moviendo:", isDragging)
      if (isLogged && (!isDragging)) {
        await generateDoc(trans)
      }
    }
  }

  const deleteText = () => {
    setOperationDatetime(getCurrentUtcDateTime())
    setOperationId(uuidv4())
    setAdditionalText('')
    setText('')
    setTrans('')
    setIsGenerated(false) // Copy the content of the additional text box
  };

  const loginSuccess = (token) => {
    console.log(token)
    setToken(token)
    setIsLogged(true)
  };

  const openCloseTras = () => {
    console.log("Tras is open:", trasOpen)
    setTrasOpen(!trasOpen)
  };

  const emailSuccess = (email, picture) => {
    setUser(email)
    setPicture(picture)
  };

  const logout =  () => {
    setIsLogged(false)
    Cookies.remove('hs-token');
  }

  const handlePressIn = () => {
    setPressTimer(setTimeout(() => {
      setDebugPosible(true);
      setIsDebugMode(true)
    }, 3000));
  };

  const handlePressOut = () => {
    clearTimeout(pressTimer);
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <GoogleOAuthProvider clientId={"540165129523-5ep3l49qpoo7nt1kuadpnqds2dc1jr57.apps.googleusercontent.com"}>
          {!isLogged && (
            <GoogleLogin
              onTokenReceived={loginSuccess}
              onEmailReceived={emailSuccess}
              firestore={saveToFirebase}
              setMedicalApp={setMedicalApp}
              setMedicalApps={setMedicalApps}
            />
          )}
        </GoogleOAuthProvider>
        <Text style={{
          position: 'fixed',
          top: 10,
          left: '51%',
          transform: 'translateX(-55px)',
          color: secondaryColor,
          fontWeight: 'bold',
          fontSize: 35
        }
        }>{medicalApp}</Text>
        <Modal
          isOpen={showModal}
          contentLabel=""
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)' // Color de fondo del modal
            },
            content: {
              backgroundColor: primaryColor,
              color: noColor,
              width: '30%', // Ancho del modal
              height: '10%', // Alto del modal
              margin: 'auto', // Centrar el modal horizontalmente
              padding: '20px', // Añadir un poco de espacio interior
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boderRadius: '50px',
              border: '0px'
            }
          }}
        >
          <h2>¡Sesión Caducada!</h2>
        </Modal>
        {isLogged && (
          <View style={styles.medicalAppSelector}>
            <select value={medicalApp} onChange={handleSelectMedicalApp}>
              {/* Iteramos sobre la lista de aplicaciones médicas y generamos una opción para cada una */}
              {medicalApps.map((app, index) => (
                <option key={index} value={app.app}>
                  {app.app}
                </option>
              ))}
            </select>
          </View>
        )}
        {(isLogged && picture != "") && (
          <img style={styles.pic} src={picture} onClick={logout}/>
        )}
        <LanguageSelector
          firebase={saveToFirebase}
          current={language}
          available={languages}
          onChange={handleLanguageOnChangeEnd}
          onChangeEnd={handleLanguageOnChangeEnd}
          setTranslation={setTranslation}
        />
      </View>
      <View style={styles.inputContainer}>
        <View style={styles.textboxContainer}>
          <View style={styles.outputContainer}>
            {isLogged && languageConfig && languageConfig.fields && Object.keys(languageConfig.fields).length > 0 ? (
              Object.entries(languageConfig.fields).sort(([, fieldA], [, fieldB]) => fieldA.order - fieldB.order).map(([key, field]) => (
                <MedicalForm
                  key={key}
                  name={field.name}
                  placeholder={field.placeholderFieldMsg}
                  value={modelOutput[key] || ""}
                  isGenerated={isGenerated}
                  isLogged={isLogged}
                  isDebugMode={isDebugMode}
                  onChangeText={(value) => {
                    const modelOutputAux = { ...modelOutput };
                    modelOutputAux[key] = value;
                    setModelOutput(modelOutputAux);
                  }}
                />
              ))
            ) : (
              <View style={styles.imageContainer}>
                <Image
                  source={require('../assets/favicon.png')} // Reemplaza con el path correcto de tu imagen
                  style={styles.noFormImage}
                />
              </View>
            )}
          </View>
          <View style={trasOpen ? styles.newTransContainerOpen : styles.newTransContainerClose}>
            <TranscriptionField
              transcription={trans}
              isLogged={isLogged}
              isDebugMode={isDebugMode}
              trasOpen={trasOpen}
              openCloseTras={openCloseTras}
              setTrans={setTrans}
            />
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <MainMenu
          isLoading={isLoading}
          isDebugMode={isDebugMode}
          isLogged={isLogged}
          isRecording={isRecording}
          isGenerated={isGenerated}
          isReadyToProcess={isReadyToProcess}
          isWaitingRecord={isWaitingRecord}
          connected={connected}
          startDebug={startDebug}
          startRecording={startRecording}
          stopRecording={stopRecording}
          copyText={copyText}
          deleteText={deleteText}
          processRecord={processRecord}
          LoadingAnimation={LoadingAnimation}
          TraceOperationRPA={trace_operation_rpa}
        />
      </View>

      <View style={styles.connected}>
        <Icon
          name={connected ? 'link' : 'unlink'}
          size={'20px'}
          color={connected ? 'green' : 'red'}
        />
      </View>
      {debugPosible && (
        <View style={styles.modeDebug}>
          <Icon
            name={isDebugMode ? 'unlock' : 'lock'}
            size={'20px'}
            color={activeColor}
          />
          <Switch
            value={isDebugMode}
            onValueChange={toggleSwitchDebug}
            color={activeColor}
          />
        </View>
      )}
      {!debugPosible && (
        <View style={styles.debug}>
          <TouchableOpacity
            onPressIn={handlePressIn}
            onPressOut={handlePressOut}
            style={{ width: 60, height: 60, backgroundColor: 'transparent' }}
          >
            <Text style={{ color: 'transparent' }}>Botón Invisible</Text>
          </TouchableOpacity>
        </View>
      )}
      <div style={versionStyle}>{appVersion}/{modelVersion}</div>
    </View>

  );
};

const medicalBlue = '#014b51';
const medicalGreen = '#638889';
const white = 'white';

const styles = StyleSheet.create({
  imageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  debug: {
    position: 'fixed',
    bottom: 0,
    left: 60,
    zIndex: 2
  },
  modeDebug: {
    position: 'fixed',
    bottom: 10,
    left: 60,
    zIndex: 2
  },
  mode: {
    position: 'fixed',
    bottom: 10,
    left: 15,
    zIndex: 2
  },
  connected: {
    position: 'fixed',
    top: 10,
    right: 25,
    zIndex: 2
  },
  medicalAppSelector: {
    position: 'fixed',
    top: 65,
    left: '50%',
    transform: 'translateX(-62.5px)',
    width: 125,
    zIndex: 2,
  },
  pic: {
    width: 60,
    height: 60,
    borderRadius: 30,
    border: '2px solid white',
    boxShadow: '0 0 0 1px #000',
    marginBottom: 10
  },
  header: {
    paddingLeft: '35px',
    paddingRight: '45px',
    paddingTop: '35px',
    paddingBottom: '10px',
    position: 'fixed',
    top: '0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: primaryColor,
    zIndex: 1
  },
  container: {
    flex: 1,
    alignItems: 'center',
    padding: 20,
    backgroundColor: primaryColor,
  },
  noFormImage: {
    width: 100, // Ajusta el tamaño según sea necesario
    height: 100, // Ajusta el tamaño según sea necesario
    resizeMode: 'contain',
    paddingTop: '400px',
  },
  footer: {
    padding: '15px',
    position: 'fixed',
    bottom: '0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: primaryColor,
    zIndex: 1
  },
  inputContainer: {
    width: '95%',
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'row',
    alinItems: 'center',
    flexWrap: 'nowrap'
  },
  textboxContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alinItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap'
  },
  outputContainerMobile: {
    marginTop: '100px',
    marginBottom: '45px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alinItems: 'stretch',
    flexWrap: 'nowrap',
  },
  outputContainer: {
    marginTop: '100px',
    marginBottom: '45px',
    paddingRight: '30px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alinItems: 'stretch',
    flexWrap: 'nowrap',
  },
  transContainer: {
    marginTop: '90px',
    marginBottom: '25px',
    paddingTop:'25px',
    display: 'flex',
    width: '25%',
    flexDirection: 'column',
    justifyContent: 'center',
    alinItems: 'stretch',
    flexWrap: 'nowrap',
    marginRight: '20px'
  },
  newTransContainerClose: {
    position: 'fixed',
    height:'100%',
    width: '35px',
    right:0,
    zIndex: 2
  },
  newTransContainerOpen: {
    position: 'fixed',
    height:'100%',
    width: '90%',
    right:0,
    zIndex: 2
  },
  input: {
    flex: 1,
    borderColor: secondaryColor,
    borderWidth: 2,
    borderRadius: 10,
    padding: 10,
    backgroundColor: noColor,
    shadowColor: '#000', // Color de la sombra
    shadowOffset: { width: 2, height: 0 }, // Desplazamiento de la sombra (eje x, eje y)
    shadowOpacity: 0.3, // Opacidad de la sombra
    shadowRadius: 0,
    maxHeight: '100%',
    height: '100%',
    maxWidth: '100%',
    width: '100%'
  },
  inputText: {
    color: secondaryColor,
  },
  inputTrans: {
    color: secondaryColor,
    flex: 1,
    height: '100%',
    maxHeight: '100%'
  },
  resultText: {
    marginTop: 20,
    fontSize: 16,
    color: white,
  }
});

export default MainScreen;
