import UK from "./UK.jpg";
import ES from "./ES.jpg";
import JA from "./JA.jpg";
import MX from "./MX.jpg";
import EC from "./EC.jpg";
import CA from "./CA.jpg";
import EU from "./EU.jpg";
import AR from "./AR.jpg";
import IT from "./IT.jpg";

export const flags = {
  IT: {
    name: "IT",
    img: IT,
    annyangConfig: "it-IT",
    palm2Config: "IT",
    country: "italy"
  },
  CA: {
    name: "CA",
    img: CA,
    annyangConfig: "ca",
    palm2Config: "CA",
    country: "spain/cat"
  },
  EU: {
    name: "EU",
    img: EU,
    annyangConfig: "eu",
    palm2Config: "EU",
    country: "spain/eu"
  },
  ES: {
    name: "ES",
    img: ES,
    annyangConfig: "es-ES",
    palm2Config: "ES",
    country: "spain"
  },
  MX: {
    name: "MX",
    img: MX,
    annyangConfig: "es-MX",
    palm2Config: "MX",
    country: "spain"
  },
  EC: {
    name: "EC",
    img: EC,
    annyangConfig: "es-EC",
    palm2Config: "EC",
    country: "spain"
  },
  JA: {
    name: "JA",
    img: JA,
    annyangConfig: "ja",
    palm2Config: "",
    country: "japan"
  },
  UK: {
    name: "UK",
    img: UK,
    annyangConfig: "en-GB",
    palm2Config: "",
    country: "unitedkingdom"
  },
  AR: {
    name: "AR",
    img: AR,
    annyangConfig: "ar-MA",
    palm2Config: "",
    country: "morocco"
  }
};
