const API_ENDPOINT = "europe-west1-aiplatform.googleapis.com";

const format = (template, ...args) => {
    return template.replace(/{(\d+)}/g, (match, index) => {
      const argIndex = parseInt(index) - 1; // Ajustar el índice para que empiece desde 0
      return typeof args[argIndex] !== 'undefined' ? args[argIndex] : match;
    });
  };
export const FieldTypes = {
    CLINICHISTORY: 'clinicHistory',
};

export const getTextPalm = async (chat, token, languageConfig, languagePrompt, type) => {
    const URL = `https://${API_ENDPOINT}/v1/projects/health-sage/locations/europe-west1/publishers/google/models/gemini-1.5-flash-001:streamGenerateContent`;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log("Chat:", chat)
    console.log("Prompt:",format(languagePrompt.prompt,chat))
    const data = {
        contents: [
            {
                role: "user",
                parts: [
                    {
                        text: format(languagePrompt.prompt,chat)
                    }
                ]
            }
        ],
        generation_config: {
            maxOutputTokens: 4096,
            temperature: 0.5,
            topP: 1
        },
        safetySettings: [
            {
                category: "HARM_CATEGORY_HATE_SPEECH",
                threshold: "BLOCK_MEDIUM_AND_ABOVE"
            },
            {
                category: "HARM_CATEGORY_DANGEROUS_CONTENT",
                threshold: "BLOCK_MEDIUM_AND_ABOVE"
            },
            {
                category: "HARM_CATEGORY_SEXUALLY_EXPLICIT",
                threshold: "BLOCK_MEDIUM_AND_ABOVE"
            },
            {
                category: "HARM_CATEGORY_HARASSMENT",
                threshold: "BLOCK_MEDIUM_AND_ABOVE"
            }
        ]
    };
    console.log(data)
    
    const response = await fetch(URL, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      console.error(response);
        return {
            dto: undefined,
            status: response.status
        }
      throw new Error("Request failed " + response.statusText);
    }
    const result = await response.json();
    console.log(result)
    var text = ""
    for (let index = 0; index < result.length; index++) {
        text+= result[index].candidates[0].content.parts[0].text;  
    }
    console.log("PRE:",text)
    const regex = /\{\s+("([^"]+)":\s*"([^"]*)",?\s*)+\}/g;
    const matches = text.match(regex);
    text = matches[0]
    // text = text.replace(/^```json\s*|\s*```$/g, '')

    console.log("POST:",text)
    var res = JSON.parse(text);
    console.log("RES:", res)
    var dto = {
        dto : res,
        inputCost: result[result.length-1].usageMetadata.promptTokenCount,
        outputCost: result[result.length-1].usageMetadata.totalTokenCount
    }
    return dto;
  };


  export const offuscateText = async (input, token) => {
    const URL = `https://${API_ENDPOINT}/v1/projects/health-sage/locations/europe-west1/publishers/google/models/gemini-1.5-flash-001:streamGenerateContent`;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log("INPUT:", input)
    const prompt = "You will be given a text that may contain personal data such as names, addresses, identification numbers, and phone numbers. Your task is to obfuscate these data by replacing them with the corresponding field type and a person label. Respect the original language. Use the following conventions: Names: [name, personX] where X is a number identifying the person. Addresses: [address] Identification numbers (ID, passport, etc.): [id_number] Phone numbers: [phone_number] Example text: 'Juan Pérez vive en la Calle Falsa 123. Su número de teléfono es 555-1234 y su DNI es 12345678Z. María Gómez, su esposa, también vive en la misma dirección y su número de teléfono es 555-5678.' Obfuscated text: '[name, person1] vive en [address]. Su número de teléfono es [phone_number] y su DNI es [id_number]. [name, person2], su esposa, también vive en la misma dirección y su número de teléfono es [phone_number].' Text to obfuscate: "+input
    console.log("PROMPT:", prompt)
    const data = {
        contents: [
            {
                role: "user",
                parts: [
                    {
                        text: prompt
                    }
                ]
            }
        ],
        generation_config: {
            maxOutputTokens: 4096,
            temperature: 0.5,
            topP: 1
        },
        safetySettings: [
            {
                category: "HARM_CATEGORY_HATE_SPEECH",
                threshold: "BLOCK_MEDIUM_AND_ABOVE"
            },
            {
                category: "HARM_CATEGORY_DANGEROUS_CONTENT",
                threshold: "BLOCK_MEDIUM_AND_ABOVE"
            },
            {
                category: "HARM_CATEGORY_SEXUALLY_EXPLICIT",
                threshold: "BLOCK_MEDIUM_AND_ABOVE"
            },
            {
                category: "HARM_CATEGORY_HARASSMENT",
                threshold: "BLOCK_MEDIUM_AND_ABOVE"
            }
        ]
    };
    console.log(data)
    
    const response = await fetch(URL, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      console.error(response);
        return {
            dto: undefined,
            status: response.status
        }
    }
    const result = await response.json();
    console.log(result)
    var text = ""
    for (let index = 0; index < result.length; index++) {
        text+= result[index].candidates[0].content.parts[0].text;  
    }
    console.log("PRE OFFUSCATE:",text)
    return {
        dto: text,
        status: response.status
    }
  };


  export const diariceText = async (input, token) => {
    const URL = `https://${API_ENDPOINT}/v1/projects/health-sage/locations/europe-west1/publishers/google/models/gemini-1.5-pro-001:streamGenerateContent`;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log("INPUT:", input)
    const prompt = "Please transcribe the following conversation between a doctor and one or several patients. Indicate who is speaking: [Patient], [Doctor], or [Unknown] if the speaker cannot be identified by the phrase. Especially in the presentation. If the last line does not have a label, you need to label that line. If the text does not contain a conversation, return the same text. If the language is Basque, translate it into Spanish. This is the text: '"+input+"'"
    console.log("PROMPT:", prompt)
    const data = {
        contents: [
            {
                role: "user",
                parts: [
                    {
                        text: prompt
                    }
                ]
            }
        ],
        generation_config: {
            maxOutputTokens: 4096,
            temperature: 0.5,
            topP: 1
        },
        safetySettings: [
            {
                category: "HARM_CATEGORY_HATE_SPEECH",
                threshold: "BLOCK_MEDIUM_AND_ABOVE"
            },
            {
                category: "HARM_CATEGORY_DANGEROUS_CONTENT",
                threshold: "BLOCK_MEDIUM_AND_ABOVE"
            },
            {
                category: "HARM_CATEGORY_SEXUALLY_EXPLICIT",
                threshold: "BLOCK_MEDIUM_AND_ABOVE"
            },
            {
                category: "HARM_CATEGORY_HARASSMENT",
                threshold: "BLOCK_MEDIUM_AND_ABOVE"
            }
        ]
    };
    console.log(data)
    
    const response = await fetch(URL, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      console.error(response);
        return {
            dto: undefined,
            status: response.status
        }
    }
    const result = await response.json();
    console.log(result)
    var text = ""
    for (let index = 0; index < result.length; index++) {
        text+= result[index].candidates[0].content.parts[0].text;  
    }
    console.log("DIARIZED TEXT:",text)
    return {
        dto: text,
        status: response.status
    }
  };